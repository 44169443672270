import React from "react"
import { Link } from "gatsby"

import service1Icon from "../../assets/images/icons/code-security.png"
import service2Icon from "../../assets/images/icons/cloud-security.png"
import service3Icon from "../../assets/images/icons/idm-security.png"
import service4Icon from "../../assets/images/icons/siem-security.png"
import service5Icon from "../../assets/images/icons/network-security.png"

const ServicesOne = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Secure your Business against Cyber-Attacks</h2>
          <p>
            With the ongoing adoption of the Cloud across the software industry,
            cyber security threats have become even more dangerous for daily IT
            business. Trust management and intrusion prevention are vital in
            complex online systems. Arch Forge Solutions has the expertise to
            build complete security solutions for your infrastructure and
            applications.
          </p>
          <h3>Here is what we can do:</h3>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service1Icon} alt="code audit" width="70" />
              </div>

              <h3>
                {/* <Link to="/service-details"> */}
                Code Vulnerability Assessment
                {/* </Link> */}
              </h3>

              <p>
                Audit of product software code based on OWASP Top-10 model.
                Checks for WebApps, Java/NodeJS backend modules, Mobile Native
                Apps, Hybrid Apps.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service2Icon} alt="cloud security" width="70" />
              </div>

              <h3>
                {/* <Link to="/service-details"> */}
                Cloud/On-Premise Solution Security
                {/* </Link> */}
              </h3>

              <p>
                Audits of proper secret management. Cloud product
                selection/configuration check. Encryption check for transited
                data and persistence.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service3Icon} width="70" />
              </div>

              <h3>
                {/* <Link to="/service-details"> */}
                IDM System Security
                {/* </Link> */}
              </h3>

              <p>
                Verification for proper user/role management. Client checks for
                correct SSO flows. Session and token management life cycle
                check.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service4Icon} width="75" />
              </div>
              <h3>
                {/* <Link to="/service-details"> */}
                SIEM System hardening
                {/* </Link> */}
              </h3>
              <p>
                Security information and event management for projects. Setup of
                proper monitoring, analysis, alerting, and prevention control.
                Setup and Deployment of Intrusion Detection/Prevention Systems.
              </p>
              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 display:inline-block text-center">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service5Icon} alt="network security" width="75" />
              </div>
              <h3>
                {/* <Link to="/service-details"> */}
                Network Penetration Tests
                {/* </Link> */}
              </h3>
              <p>
                Manual and automated network system tests to find used product
                vulnerabilities. Wifi and ethernet audits with router and server
                analysis.
              </p>

              <Link className="view-details-btn" to="/contact">
                Get Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesOne
